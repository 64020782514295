// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-for-law-firms-js": () => import("./../src/pages/for-law-firms.js" /* webpackChunkName: "component---src-pages-for-law-firms-js" */),
  "component---src-pages-for-non-profits-js": () => import("./../src/pages/for-non-profits.js" /* webpackChunkName: "component---src-pages-for-non-profits-js" */),
  "component---src-pages-for-private-equity-js": () => import("./../src/pages/for-private-equity.js" /* webpackChunkName: "component---src-pages-for-private-equity-js" */),
  "component---src-pages-for-small-business-js": () => import("./../src/pages/for-small-business.js" /* webpackChunkName: "component---src-pages-for-small-business-js" */),
  "component---src-pages-healthcheck-app-js": () => import("./../src/pages/healthcheck-app.js" /* webpackChunkName: "component---src-pages-healthcheck-app-js" */),
  "component---src-pages-healthcheck-js": () => import("./../src/pages/healthcheck.js" /* webpackChunkName: "component---src-pages-healthcheck-js" */),
  "component---src-pages-how-it-works-js": () => import("./../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-subscribe-js": () => import("./../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-whats-included-js": () => import("./../src/pages/whats-included.js" /* webpackChunkName: "component---src-pages-whats-included-js" */)
}

